import { Component } from 'react';
import qrAnimate from "../qr-code.json";
import Lottie, { useLottie } from "lottie-react";
import { faClock, faCheckDouble, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BarcodeReader from 'react-barcode-reader'
import { setKembali } from '../api/Register';
import { Toast as ToastComponent } from '../components/Toast';
import toast, { Toaster } from 'react-hot-toast';


const QR = () => {
    const options = {
        animationData: qrAnimate,
        loop: true,
        autoplay: true,
    };
    const { View } = useLottie(options)
    return View;
}

const KembalikanKartuFn = () => {
    return (
        <div>
            <KembalikanKartu />
        </div>
    )
}
const CardRecent = ({ nama, asal, isSuccess=true, retry=false, ulangi }) => {
    return (
        <div className="p-2 rounded-md shadow-sm border-2 border-gray-300 border-dashed bg-gray-50 mb-2">
            <div >
                <div className="text-md ">{ nama }</div> 
                <div className="italic text-sm text-gray-500">{ asal }</div>
            </div>
            <div className="text-right -mt-5">
                {
                    (retry) ?
                    <FontAwesomeIcon className="text-lg text-red-500" icon={ faRefresh } onClick={ () => ulangi(nama) } />
                    :
                    (isSuccess) ?
                    <FontAwesomeIcon className="text-lg text-green-500" icon={ faCheckDouble } />

                    :
                    <FontAwesomeIcon className="text-lg text-gray-500" icon={ faClock } />
                }
            </div>
        </div>
    )
}
class KembalikanKartu extends Component{
    constructor(props){
        super(props)
        this.state =  {
            recent: []
        }
    }
    componentDidMount = () => {
        
    }
    handleScan = (data) => {
        let recent = this.state.recent;
            recent.push({ nama: data, asal: "pending", isSuccess: false  })
           
        this.setState({
            recent: recent
        }, () => {
            const self = this;
           self.request(data)
        })
        
    }
    request = (data) => {
        const self = this
        setKembali({ kartu: data }).then((res) => {
            console.log(res)
                if (res.status === 405) {
                    let newRecent = self.state.recent;
                    newRecent.map((x) => {
                        if(x.nama === data ){
                            x.nama = "Kartu Sudah Dikembalikan"
                            x.asal = "dikembalikan"
                            x.isSuccess = true;
                            x.retry =false
                        }
                        return x;
                    })
                    self.setState({
                        recent: newRecent
                    })
                }else if(res.status === 200){
                    let newRecent = self.state.recent;
                    newRecent.map((x) => {
                        if(x.nama === data ){
                            x.nama = res['data']['nama'];
                            x.asal = res['data']['asal'];
                            x.isSuccess = true;
                            x.retry = false
                        }
                        return x;
                    })
                    self.setState({
                        recent: newRecent
                    })
                }else{
                    let newRecent = self.state.recent;
                    newRecent.map((x) => {
                        if(x.nama === data ){
                     
                            x.isSuccess = false;
                            x.retry     = true;
                            x.asal      = "gagal"
                        }
                        return x;
                    })
                    self.setState({
                        recent: newRecent
                    })
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="Gagal Menghubungi Server" />
                    })
                }
            })
    }
    handleError = (err) => {
        console.log(err)
    }
    render(){
        const { handleError, handleScan, request } = this;
        const { recent } = this.state;
        return(
            <div className="min-h-screen bg-gray-100 p-5 grid grid-cols-3 gap-5 justify-center">
                <BarcodeReader
                    onError={handleError}
                    onScan={handleScan}
                />
                <Toaster position="bottom-right"/>

                <div>
                <div className="bg-white rounded-md shadow-md">
                        <div className="p-4  text-white text-center bg-blue-500 rounded-t-md">
                            Recently Update
                        </div>
                        <div className="p-3 h-[75vh] overflow-y-scroll	">
                            {
                                (recent.length === 0) ?
                                <div className="text-center italic text-gray-500 pt-[31vh]">
                                    Belum Ada Recent
                                </div>
                                :
                                recent.reverse().slice(0,20).map((x, i) => {
                                    return (
                                        <div key={i}>
                                            <CardRecent { ...x } ulangi={ request } />
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="col-span-2">
                              
                    <div className="bg-white h-[84vh] rounded-md shadow-md">
                        <div className="p-4  text-white text-lg text-center bg-emerald-500 rounded-t-md">
                            Kembalikan Kartu
                        </div>
                        <div className="p-3 flex justify-center">
                
                            
                            <div className="w-40  mt-[10%]">
                                        <QR />
                                </div>
                         
                        </div>
                        <div className=" mt-2 pb-5">      
                               
                               <div className="italic text-xl text-center">Menunggu Scanner...</div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default KembalikanKartuFn
const base_url = localStorage.getItem("base_url");
export const backEndUrl = () => {
    // // Dev
  //  return "http://localhost/bukutamu/"
    // // Prod
    // const url = new URL(window.location.href);

    // return url.origin.pathname + "/";
   return base_url;
}


export const SubDir = "";

export const tipeData = () => {
    return {
        "1": "umum",
        "2": "kedinasan",
        "3": "sertifikasi",
        "4": "rekanan",
        "5": "pkl"
    }
}

export const getTipeData = (str) => {
    const listType = tipeData();
    let nomor = "";
    for(let data in listType){
        if (listType[data] === str) {
            nomor = data;
        }
    }
    return nomor;
}
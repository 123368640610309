import { useEffect, useState }  from 'react';
import { isRequired, resetValue } from '../api/Value';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../components/Toast';
import { faTimes, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Lottie, { useLottie } from "lottie-react";
import qrAnimate from "../qr-code.json";
import Datatable from '../components/Datatable'


const TableFormPeserta = ({  DataRender, deletePeserta, multiple_id_card, bindNama }) => {
 
    const TheadComponent = ({ sortData }) => {
      return (
        <thead>
          <tr>
              <td width="5%" className=" border-2  border-gray-300 p-2 w-20 text-center">No</td>
              <td width="40%" className=" border-2 border-gray-300 p-2 text-center">Nama</td>
              <td width="40%" className=" border-2 border-gray-300 p-2 text-center">ID Card</td>
              <td width="15%" className=" border-2 border-gray-300 p-2 text-center">Aksi</td>
             
          </tr>
        </thead>
        
      )
    }
    return (
      <div>
          <Datatable 
            Head={TheadComponent}
            Data={DataRender}
            Entries={[]}
            Body={(row, index) => {
                const mod = (index%2 == 0) ? " bg-gray-100 " : "";
                
              return (
                <tr key={ index }>
                    <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                      { row.index+1 } 
                    </td>
                    <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                        <input type="text" value={ row.nama } placeholder="Nama"  onChange={(e) => bindNama(e, row.index) } className="bg-gray-200 w-full w-full p-[8px] border-2 border-gray-300 rounded-md " name="nama" />
                        
                    </td>
                    <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                 
                       { multiple_id_card[index]}

                    
                    </td>
                    <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                        <FontAwesomeIcon className="text-lg text-red-600" onClick={() =>  deletePeserta(row.index) } icon={faTimes}/>
                    </td>
                    
                    
                </tr>
              )
            }}
          />
      </div>
    )
  }
  
const TablePeserta = ({  DataRender, hapusPermintaan, setEdit }) => {
 
 const TheadComponent = ({ sortData }) => {
   return (
     <thead>
       <tr>
           <td className=" border-2  border-gray-300 p-2 w-20 text-center" >No</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Nama</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Asal</td>
           <td className=" border-2 border-gray-300 p-2 text-center">HP</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Tanggal Awal</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Tanggal Akhir</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Aksi</td>
          
       </tr>
     </thead>
     
   )
 }
 return (
   <div>
       <Datatable 
         Head={TheadComponent}
         Data={DataRender}
         Entries={[]}
         Body={(row, index) => {
           const mod = (index%2 == 0) ? " bg-gray-100 " : "";
     
           return (
             <tr key={ index }>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                   { row.index + 1 } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top" + mod}>
                   { row.nama } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top" + mod}>
                   { row.asal } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                   { row.no_hp } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                   { row.tgl_awal } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                   { row.tgl_akhir } 
               </td>
            
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                   <div>
                     <FontAwesomeIcon className="text-orange-600" onClick={() =>  setEdit(row.index) } icon={faEdit}/>
                         &nbsp;
                     <FontAwesomeIcon className="text-red-600" onClick={ () => hapusPermintaan(row.id_permintaan) } icon={faTrash}/>

                   </div>
               </td>
             </tr>
           )
         }}
       />
   </div>
 )
}

const QR = () => {
    const options = {
        animationData: qrAnimate,
        loop: true,
        autoplay: true,
    };
    const { View } = useLottie(options)
    return View;
}
const PklForm = ({ multiple_id_card, submitPkl, permintaan, hapusPermintaan,updateMultipleIdCard, handleScan }) => {
    const [ loader, setLoader ] = useState(false);
    const [ state, setState ] = useState({
        asal: "",
        no_hp: "",
        tgl_awal: "",
        tgl_akhir: "",
        peserta: [],
        txt_nama: ""
    })
    const handleForm = (e) => {
        const name = e.target.name
        const value = e.target.value;
        state[name] = value;
        setState(prevState =>( {
            ...prevState,
            ...state
        }))
    }
  
    const addPeserta = () => {
        if (state.txt_nama !== "") {
            let peserta = state.peserta;
            peserta.push({ nama: state.txt_nama, index: peserta.length});
            setState(prevState => ({ 
                ...prevState,
                peserta: peserta,
                txt_nama: ""
            }))
        }        
    }
    const deletePeserta =(index) => {
        let peserta = state.peserta;
            peserta.splice(index, 1)
            setState(prevState =>( {
                ...prevState,
                peserta: peserta
            }))
    }
    const bindNama = (e, index) => {
        let peserta = state.peserta;
        peserta[index]['nama']= e.target.value;
        setState(prevState => ({ ...prevState, peserta: peserta }))
    }
    const submitData = async () => {
        const check = isRequired(state, ["asal", "no_hp", "tgl_awal", "tgl_akhir"]);
        if (check.status === false) {
            toast.custom((t) => {
                return <ToastComponent param={t} message={check.message} />
            })
            return false ; 
        } 
        if (state.peserta.length === 0) {
            toast.custom((t) => {
                return <ToastComponent param={t} message="Mohon Tambah Peserta PKL" />
            })
            return false;
        }
        if (state.peserta.length !== multiple_id_card.length) {
            toast.custom((t) => {
                return <ToastComponent param={t} message="Mohon Lenkapi ID Card" />
            })
            return false;
        }
        setLoader(true)
        await submitPkl(state)
        setLoader(false);
        setState({
            asal: "",
            no_hp: "",
            tgl_awal: "",
            tgl_akhir: "",
            peserta: [],
            txt_nama: ""
        })
        
    }
    const setEdit = (index) => {
        const token = permintaan[index]['token'];
        const id_permintaan = permintaan[index]['id_permintaan'];
        let id_card = [];
        setState({
            ...permintaan[index], 
            tgl_awal: permintaan[index]['tgl_awal'].split("-").reverse().join("-"),
            tgl_akhir: permintaan[index]['tgl_akhir'].split("-").reverse().join("-"),
            peserta: 
            permintaan.filter((x) => { return (x.token === token && x.token  || x.id_permintaan === id_permintaan) }).map((x,i) =>{
                x.index = i;
                id_card.push(x.id_card)
                return x
            })
        
        })
        updateMultipleIdCard(id_card)
        document.getElementById("nama_instansi").focus();
    }

    useEffect(() => {
        return () => {
            
        };
    }, []);

    
    return (
        <div>
            <div  className="grid grid-cols-2 p-6  pt-4 gap-5">
                <div>
                    <div>
                        <div className="text-md mb-2">Nama Instansi</div>
                        <input type="text" id="nama_instansi" onChange={handleForm } value={ state.asal } placeholder="Nama Instansi" className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="asal" />
                    </div>
                    <div className="mt-3">
                        <div className="text-md mb-2">No HP Koordinator</div>
                        <input type="text" onChange={ handleForm } value={ state.no_hp } placeholder="No HP Koordinator" className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="no_hp" />
                    </div>
                    <div className="mt-3">
                        <div className="flex gap-5 justify-between">
                            <div className="flex-1 w-20">
                                <div className="text-md mb-2">Tanggal awal</div>
                                <input type="date" onChange={ handleForm } value={ state.tgl_awal } placeholder="Tgl Awal" className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="tgl_awal" />
                            </div>
                            <div className="pt-10">s.d</div>
                            <div className="flex-1 w-20">
                                <div className="text-md mb-2 ">Tanggal akhir</div>
                                <input type="date" onChange={ handleForm } value={ state.tgl_akhir } placeholder="Tgl Akhir" className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="tgl_akhir"/>
                            </div>
                        </div>
                    </div>
                    
                      <div className="mt-3">
                          <div className="flex mt-4 border-dashed border-2 border-gray-400 rounded-md p-2">
                              <div className="w-10"><QR /></div>
                              <div className="pl-4 pt-2 italic text-center "> 
                                  <span>Mohon Scan { state.peserta.length } ID Card PKL</span>
                              </div>
                          </div>
                      </div>
                </div>    
                <div >
                    <div className="flex gap-3">
                        <div className="flex-1 w-64">
                            <div className="text-md mb-2">Nama</div>
                            <input type="text" value={ state.txt_nama } onChange={ handleForm } onKeyPress={(e) => { if (e.key === "Enter") {
                                addPeserta()
                            } }} placeholder="Nama" className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="txt_nama" />

                            
                        </div>
                        <div className="pt-8">
                            <button onClick={ addPeserta } className="w-10 h-10 rounded-full bg-teal-500 hover:bg-teal-400 active:bg-teal-600 text-white">
                                <FontAwesomeIcon icon={faPlus}/>
                            </button>
                        </div>
                    </div>
                  
                    <TableFormPeserta 
                    bindNama ={bindNama}
                    DataRender={state.peserta} 
                    deletePeserta={ deletePeserta } 
                    multiple_id_card={multiple_id_card}/>
                    <div className="text-right pt-5">
                        {
                            loader === false ?
                                <button onClick={ submitData } className="bg-emerald-600 hover:bg-emerald-500 active:bg-emerald-700 text-white w-full rounded-md p-2 text-white">SIMPAN</button>

                            :
                                <button className="opacity-70 bg-emerald-600 hover:bg-emerald-500 active:bg-emerald-700 text-white w-full rounded-md p-2 text-white">Mohon Tunggu</button>

                        }
                    </div>
                </div>
               
            </div>
            <div className="p-5">
            <TablePeserta 
            DataRender = { permintaan } 
            hapusPermintaan={ hapusPermintaan } 
            setEdit = { setEdit }
            />

            </div>
            
        </div>
    )
}
export default PklForm
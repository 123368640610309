import { useState, Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight,faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
const configSort = {
    up: <FontAwesomeIcon icon={faSortUp}/>,
    neutral: <FontAwesomeIcon icon={faSort}/>,
    down: <FontAwesomeIcon icon={faSortDown}/>
}
export const IconSort = (order,sortType ) => {
    let type    = order.filtered;
    let desc    =  ("-" + sortType);
  
    let me      = (desc ==  order.key || sortType == order.key)

    return (type == "" || me == false) ? configSort["neutral"] : (type == "asc") ? configSort["up"] : configSort["down"]
}
export default class RDataTable extends Component{
     constructor(props){
         super(props)
         this.state = {
            pagination:[],
            showing:10,
            search:"",
            pagingActive:0,
            group_paging:0,
            start_page:0,
            order_by:{
                key:"",
                filtered:"",
                position:""
            }
         }
     }
        handleSearch = (e) => {
            this.setState({
                search:e.target.value,
                pagingActive:0,
                start_page:0
            })
        }
        handlePaging = (e,index,end) => {
            console.log(index);
            
            let test = {
                pagingActive:index
            };
            if (typeof index == "object") {
                test['pagingActive'] = index.index;
                test['start_page'] = index.next_group;
            }
            e.preventDefault()
            this.setState(test,() => {
            
            })
        }
        pindahGroup = (e = "",pindahGroup) => {
          
            if (e == "") {
                
            }else{

                e.preventDefault()
            }
            this.setState({
                start_page:pindahGroup
            })
        }
        handleShowing =  (value) => {
            let start_page = this.state.start_page;
            let showing = parseInt(value.target.value);
            this.setState({
                showing:showing,
                start_page:0,
                pagingActive:0
            });
        }
        handleSort = (order_by) => {
            let order_by_state = this.state.order_by;
            let strip          = "";
            if (order_by_state.filtered == "" || order_by_state.filtered == "desc") {
                order_by_state['filtered'] = "asc";
            }else{
                order_by_state['filtered'] = "desc";
                strip           = "-"; 
            }
            order_by_state['key'] = strip + order_by;
            this.setState({
                order_by:order_by_state
            });
        }

        dynamicSort = (property) => {
            var sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                let param_1 = (a[property] == null) ? "" : `${a[property]}`.toLowerCase().trim();
                let param_2 = (b[property] == null) ? "" : `${b[property]}`.toLowerCase().trim();
                
                if (!isNaN(parseInt(param_1))) {
                    return (sortOrder == -1) ? parseInt(param_2) - parseInt(param_1): parseInt(param_1) - parseInt(param_2);
                }
                /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
                */
                var result = (param_1 < param_2) ? -1 : (param_1 > param_2) ? 1 : 0;
                return result * sortOrder;
            }
        }
        sortData = (ord) => {
            this.handleSort(ord)
        }
        exportFile = (type, title) => {
            if(type == "excel"){
                var ws = XLSX.utils.json_to_sheet(this.props.Data);
                /* add to workbook */
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, title);
                let name     = (!title) ? "React Datatable": title;
                /* generate an XLSX file */
                XLSX.writeFile(wb, name + ".xlsx");
            }else if (type == "print") {
                var myWindow = window.open("", "", "width=1000,height=1000");
                let name     = (!title) ? "React Datatable": title;
                let fullList   = this.props.Data;
                let entries    = (fullList.length > 0) ? Object.getOwnPropertyNames(fullList[0]) : [];

                myWindow.document.write(`<h2 align="center">${name}</h2>`);
                myWindow.document.write(`<br/><table style="border:1px solid black;border-collapse: collapse;" width="100%"><thead><tr>`);
                for(let e of entries){
                    myWindow.document.write(`<th style="border:1px solid black;padding:2px;padding-right:4px;padding-left:4px;">${e.split('_').join(' ').toUpperCase()}</th>`);
                }
                myWindow.document.write(`</tr>`);


                // agar sesuai yang ditampilin

                let custom_print = this.props.customPrint;

            
                    for(let v of fullList){

                        myWindow.document.write(`<tr>`);

                        if (custom_print == undefined) {
                            for(let td of entries){
                                myWindow.document.write(`<td style="border:1px solid black;padding:2px;padding-right:4px;padding-left:4px;">${v[td]}</td>`);
                            }   
                        }else {
                            myWindow.document.write(custom_print(v));
                        }
                        myWindow.document.write(`</tr>`);

                    
                    }

                myWindow.window.stop();
                myWindow.window.print();
                myWindow.window.close();
            }
        }
        render() {
            const { 
                    Head, Body, Data, Export, Entries, myMenu, hidePagination
                  } = this.props
            const { 
                    sortData, exportFile 
                    } = this
            const { 
                    search, pagingActive, order_by, showing, start_page
                   }  = this.state
            let hidePage                                = (hidePagination) ? "hidden": "";
            let param_order                             = order_by;
            let paging                                  = pagingActive;
            let pagination                              = [];
            let nomor                                   = paging;
            let next                                    = -2;
            let prev                                    = -2;
            let start_page_                              = start_page
            let myEntries                               = (Data.length > 0) ? Object.getOwnPropertyNames(Data[0]) : [];
            let fullList   = Data.filter((v,i) => {
                let bagan_cari = "";
                for(let e of myEntries){
                    bagan_cari += (v[e] == null) ? "" : v[e];
                }
                return bagan_cari.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            });

            let showing_              = parseInt(showing);
            let paging_render        = [];
            let max_paging           = Math.ceil(fullList.length / showing);
            let end_page             = max_paging;
           
            let prev_group           = -1;
            let next_group           = -1;
            let status_prev          = false;
            let lompat_pertama       = {
                index:0,
                next_group:0
            };
            let lompat_terakhir      = end_page;
            let index_terakhir       = {
                index:lompat_terakhir*(showing)-10,
                next_group:(lompat_terakhir-10 < 0) ? 0 : lompat_terakhir-10
            }
            for(let x = start_page_; x < start_page_+10;x++){
                let index  = x*(showing);    
                let active = (paging == index) ? "active" : "";

                if (paging != 0 && active != "active" && !status_prev) {
                    prev = index;
                }else if (active == "active" && x != 0 && start_page_ == x) {
          
                    prev  = {
                        index:(x / showing)-1,
                        next_group:start_page_-10
                    }
                }
                if (active == "active") {
                    next   = -1;
                    status_prev = true;
                }else if (next == -1) {
                    next = index;
                }
                let blue                            = (active == "active") ? "bg-blue-500 text-white" : "hover:bg-blue-500 hover:text-white hover:border-none hover:text-lg border-gray-200 border-2"
                paging_render[paging_render.length] = <div key={ x } onClick={(e) => this.handlePaging(e,index)} className={blue + "  w-10 h-10 text-center rounded-full pt-2 ml-1"}> {x+1}</div>
                                                          
                if (x <= max_paging && x == start_page_+9) {
                    next_group  = x+1;
                    end_page    = paging+showing;

                    if (next == -1 && active == "active") {
                        next = {
                            index:(x+1)*(showing),
                            next_group:next_group
                        }
                    }
                }

                if (x >= max_paging-1) {
                    next_group  = -1;
                    end_page    = ((paging+showing-1) > fullList.length-1) ? fullList.length : paging+showing;
                    
                    if (active == "active") {
                        next        = -1;
                    
                    }
                    break;
                }

            }
            if (start_page_ != 0) {
                prev_group  = start_page_-10;
                if (prev_group < -1) {
                    prev_group = -1;
                }
            }
            if (param_order.key != "") {
                fullList.sort(this.dynamicSort(param_order.key));
            }
            return (
        <div className="p-2 ">
        
            <div className="justify-between md:flex">
                <div className="md:flex">
                    <div>
                        <div className="mb-2">Menampilkan</div> 
                        <select name="" className="w-full border-2 p-2 rounded-md md:w-48" onChange={this.handleShowing}>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                    
                    </div>
                    <div className="pt-8">
                        {
                            (Export) ? <Export exportFile={ exportFile } /> : ""
                        }
                        
                    </div>   
                    
                </div>
               
                <div className="pt-4 md:pt-0">
                    <div className="mb-2">Cari</div> 
                    <input type="search" className="w-full border-2 p-2 rounded-md md:w-48" autoComplete="off" name="cari" onChange={this.handleSearch} value={search} id="cari" />
                </div>
            </div>
            <br />
            <div className="overflow-x-scroll	pb-5">
                <table className="w-full  border-gray-300 rounded-md border-2">
                    <Head 
                        sort={sortData}
                        order_by = {order_by}
                    />
                    <tbody>
                       {
                           (fullList.length > 0) ?
                           fullList.slice((paging <= 0) ? 0 : paging,paging+(showing)).map(Body)
                           : 
                           <tr >
                                <td colSpan="99" className="text-center p-4">
                                    Tidak Ada Data
                                
                                </td>
                           </tr>
                       }
                    </tbody>
                </table>
            </div>
            <div className="justify-between mt-2 md:flex">
                <div>
                    {
                         (hidePagination) ?
                                       <h5>{"Menampilkan " + 1 + " sampai " + fullList.length + " dari " + fullList.length + " baris"}</h5>
                                     :
                                      <h5>{"Menampilkan " + (paging+1) + " sampai " + (end_page) + " dari " + fullList.length + " baris"}</h5>
                    }
                </div>
                <div className={"overflow-x-scroll md:overflow-hidden " + hidePage}>
                    <div className="mb-3 justify-end flex" >

                        {
                            (paging == 0) ?
                                null
                            :
                                <div onClick={(e) => this.handlePaging(e,lompat_pertama)} className="hover:bg-blue-500 hover:text-white hover:border-none hover:text-lg border-gray-200 border-2 w-10 h-10 text-center rounded-full pt-2 text-gray-500">
                                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                                </div>
                        }
                       {
                           (prev >=  0 || typeof prev == "object") ?
                            <div onClick={(e) => this.handlePaging(e,prev)} className="hover:bg-blue-500 hover:text-white hover:border-none hover:text-lg border-gray-200 border-2 w-10 h-10 text-center rounded-full pt-2 text-gray-500">
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </div>
                           
                            :
                            <div className="hover:bg-blue-500 hover:text-white hover:border-none hover:text-lg border-gray-200 border-2 w-10 h-10 text-center rounded-full pt-2 text-gray-200">
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </div>
                       }

                        {
                             (prev_group != -1) ?
                                <div onClick={(e) => this.pindahGroup(e,prev_group)} className="hover:bg-blue-500 hover:text-white hover:border-none hover:text-lg border-gray-200 border-2 w-10 h-10 text-center rounded-full pt-2 ml-1 text-gray-500">
                                    ...
                                </div>
                                : 
                                null
                        }
                        { paging_render }
                        
                       {
                            (next_group != -1) ?
                                <div onClick={(e) => this.pindahGroup(e, next_group)} className="hover:bg-blue-500 hover:text-white hover:border-none hover:text-lg border-gray-200 border-2 w-10 h-10 text-center rounded-full pt-2 ml-1">
                                    ...
                                </div>
                            :
                            null
                       }
                        {
                             (next >= 0 || typeof next == "object") ?
                                <div onClick={(e) => this.handlePaging(e,next)} className="hover:bg-blue-500 hover:text-white hover:border-none hover:text-lg border-gray-200 border-2 w-10 h-10 text-center rounded-full pt-2 ml-1 text-gray-500">
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                           
                                :
                                 <div className="hover:bg-blue-500 hover:text-white hover:border-none hover:text-lg border-gray-200 border-2 w-10 h-10 text-center rounded-full pt-2 ml-1 text-gray-200">
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                        }
                        {
                            (next >= 0 || typeof next == "object") ?
                                <div onClick={(e) => this.handlePaging(e,index_terakhir)} className="hover:bg-blue-500 hover:text-white hover:border-none hover:text-lg border-gray-200 border-2 w-10 h-10 text-center rounded-full pt-2 ml-1 text-gray-500">
                                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                                </div>
                                :
                                null
                        }
                        
                    </div>
                </div>
            </div>
        </div>

    )
        }
    }

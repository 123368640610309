import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faIdCard, faPowerOff, faAbsen, faSignInAlt, faDashboard } from '@fortawesome/free-solid-svg-icons'
import React, { Fragment } from 'react';
import { Navigate, useNavigate, useLocation, NavLink } from 'react-router-dom'
import { SubDir } from '../config/Config';
const NavbarFn = ({ logout, jabatan }) => {
    const location = useLocation()
    //console.log(location)
    return (
        <div>
            <Navbar path={location.pathname} logout={ logout } jabatan={jabatan}/>
        </div>
    )
}
class Navbar extends React.Component{
    state = {
        menu: "register",
        hovering: false
    }
    setMenu = (menu) => {
        this.setState({
            menu: menu
        })
    }
    setHovering = (hovering) => {
        this.setState({
            hovering: hovering
        })
    }
    render(){
        const { setHovering, setMenu } = this;
        const { hovering, menu } = this.state
        const { path, logout, jabatan }           = this.props;
        const paramDir = (SubDir === "") ? "/" :"/" + SubDir;
       // console.log(path)
        return (
            <div className="w-full flex justify-between h-16 shadow-xl bg-gradient-to-r from-emerald-600 to-blue-500">
                <div className=" text-white  flex text-md">
                    <div className="pl-5 pt-4"> <img src={require("../esdm.png")} width="30" alt="" /> </div>
                    <div className="font-bold pl-3 pt-5">SI BUKUTAMU</div>
                    <div className="flex ml-4">
                      
                                <NavLink to={SubDir + "app/index/dashboard"} className={`pt-2 pl-5 pr-5 pt-5 hover:bg-green-900 cursor-pointer ${path === paramDir + "app" || path === paramDir + "app/index/dashboard" ? "bg-green-900" : ""}`}>
                                    <FontAwesomeIcon icon={faDashboard} />
                                    <span className="pl-1">  Dashboard</span>
                                </NavLink> 
                                <NavLink to={SubDir + "app/index/register"} className={`pt-2 pl-5 pr-5 pt-5 hover:bg-green-900 cursor-pointer ${path === paramDir + "app" || path === paramDir + "app/index/register" ? "bg-green-900" : ""}`}>
                                    <FontAwesomeIcon icon={faUser} />
                                    <span className="pl-1">  Register</span>
                                </NavLink>  
                                <NavLink  to={SubDir + "app/index/kembalikanKartu"} className={`pt-2 pl-5 pr-5 pt-5 hover:bg-green-900 cursor-pointer ${path === paramDir + "app/index/kembalikanKartu" ? "bg-green-900" : ""}`}>
                                    <FontAwesomeIcon icon={faIdCard} />
                                    <span className="pl-1">  Kembalikan Kartu</span>
                                </NavLink>
                            
                                <NavLink to={SubDir + "app/index/modeAbsen"} className={`pt-2 pl-5 pr-5 pt-5 hover:bg-green-900 cursor-pointer ${path === paramDir + "app/index/modeAbsen" ? "bg-green-900" : ""}`}>
                                    <FontAwesomeIcon icon={faSignInAlt} />
                                    <span className="pl-1">  Mode Absen</span>
                                </NavLink>    
                        
                       
                    </div>
                </div>
             
                <div className="p-5 flex">
                        <div className="text-md text-white pr-4">
                        { jabatan }

                        </div>
                        <div>
                            <FontAwesomeIcon className="text-white text-lg font-bold mt-[4px] cursor-pointer" onClick={logout} icon={faPowerOff} />

                        </div>
                </div>
            </div>
        )
    }
}

export default NavbarFn
import * as React from 'react'

import { backEndUrl, SubDir } from '../config/Config';
import axios from 'axios';
import FullLoader from '../components/FullLoader';
import { useNavigate } from 'react-router-dom'

const AuthContext = React.createContext();

const AuthReducer = (state, action) => {
    switch (action.type) {
        case "login":
            return { isLogin: true, jabatan: action.jabatan}
            break;
        case "logout":
            return { isLogin: false, jabatan: "" }
            break;
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
            break;
    }
} 
const AuthProvider = ({ children }) => {
    const [ state, dispatch ] = React.useReducer(AuthReducer, { isLogin: false, jabatan: "" });
    const [ loading, setLoading ] = React.useState(true)
    const navigate = useNavigate();

    const verifyLogin = async ({ username, password }) => {
        let form = new FormData();
            form.set("username", username);
            form.set("password", password);
        const options = {
          method: "POST",
          body: form,
           credentials: 'omit'
        }
      //  setLoading(true);
        const myUrl   =  backEndUrl() + "Auth/auth";
        
        var reqLogin = await axios.post(myUrl, form, {withCredentials: true})
          .then((res) => {
            const { data } = res;
            if(data.status === 200){
                localStorage.setItem("EXP_BUKUTAMU", data.exp);
                  dispatch({ type: 'login', jabatan: res.data.jabatan })
                  setTimeout(() => {
                    dispatch({ type: 'logout' })
                  }, 7200000);
                  navigate( SubDir + 'app/index/dashboard', { replace: true });
                 
                return data;
            }else{
                return data;                
            }
          })
          .catch(e => {
            console.log(e)
            return {
              status: 500,
              message: e.response.data.messages
            }
          })
        
      return reqLogin;
    }
    const logout = async () => {
        const reqLogout = await axios.get(backEndUrl() + "Auth/logout",{withCredentials: true})
              .then((res) => {
              // console.log(res);
                    dispatch({ type: 'logout'  })
                    navigate( SubDir + 'app/index/login', { replace: true });

                    return {
                        status: 200,
                        message: "Logout Berhasil"
                    }
              })
              .catch((e) => {
                console.log(e)
                return {
                  status: 500,
                  message: e.response
                }
              })
                          
       return reqLogout;
    }
    const meIsLogin = () => {
      axios.get(backEndUrl() + "Auth/isLogin", {withCredentials: true})
      .then((result) => {
        const { data } = result;
        console.log(data)
        if(data.status == 200) {
          dispatch({ type: 'login', jabatan: data.jabatan })
          setLoading(false);
        }else if (data.status == 500) {
          // console.log("dsajk")
          dispatch({ type: 'logout'})
        setLoading(false);
        }
        
      }).catch((err) => {

      });
    }

    React.useEffect(() => {
      
      meIsLogin()
      return {

      }
    }, []);
    
    if (loading) {
      return <FullLoader />
    }
    
    const value = {state, dispatch, verifyLogin, logout, meIsLogin};
   // meIsLogin()
    return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>
}
const useAuth = () =>  {
    const context = React.useContext(AuthReducer)
    if(context === undefined) {
        throw new Error('useAuth must be used within a CountProvider')
    }
    return context;
}
const  AuthConsumer = ({children}) => {
  return (
    <AuthContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('CountConsumer must be used within a CountProvider')
        }
        return children(context)
      }}
    </AuthContext.Consumer>
  )
}


export default { AuthConsumer: AuthConsumer, AuthProvider: AuthProvider, useAuth: useAuth }
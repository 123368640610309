import { Component, useEffect, useState }  from 'react';
import Lottie, { useLottie } from "lottie-react";
import qrAnimate from "../qr-code.json";
import { AutoComplete } from '../components/Input';
import { Switch } from '@headlessui/react'
import { faChalkboardUser, faUserGroup, faIdCard, faUser, faSchool, faPlus, faTimes, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Datatable from '../components/Datatable';
import { add, addMultiple, cariPeserta, getPegawai, getDataRegister } from '../api/Register';
import { add as addPermintaan, addPkl, getPermintaan, hapus, apiSimtra } from '../api/Permintaan';
import { isRequired, resetValue } from '../api/Value';
import { Toast as ToastComponent } from '../components/Toast';
import toast, { Toaster } from 'react-hot-toast';
import { getTipeData, tipeData } from '../config/Config';
import { getKartu } from '../api/Absen';
import BarcodeReader from 'react-barcode-reader'
import PklForm from './Pkl';
import swal from 'sweetalert';
import Auth from '../api/Auth';

const TablePeserta = ({  DataRender }) => {
 
 const TheadComponent = ({ sortData }) => {
   return (
     <thead>
       <tr>
           <td className=" border-2  border-gray-300 p-2 w-20 text-center" >No</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Nama</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Asal</td>
           <td className=" border-2 border-gray-300 p-2 text-center">No HP</td>
          
       </tr>
     </thead>
     
   )
 }
 return (
   <div>
       <Datatable 
         Head={TheadComponent}
         Data={DataRender}
         Entries={[]}
         Body={(row, index) => {
           const mod = (index%2 == 0) ? " bg-gray-100 " : "";
     
           return (
             <tr key={ index }>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                   { row.index + 1 } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top" + mod}>
                   { row.nama } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top" + mod}>
                   { row.asal } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top" + mod}>
                    { row.no_hp } 
                </td>
                
             </tr>
           )
         }}
       />
   </div>
 )
}
const QR = () => {
    const options = {
        animationData: qrAnimate,
        loop: true,
        autoplay: true,
    };
    const { View } = useLottie(options)
    return View;
}

const DetailSideBar = ({ type,setMenu, hovering, setHovering }) => {
    
    return (
        <div onMouseOver ={() => setHovering(true)} onMouseLeave={()=>setHovering(false)}  className='bg-white mt-3 fixed z-10 p-3  w-16 transition-all	 hover:w-44 rounded-r-lg bg-gradient-to-b from-teal-500 to-emerald-500 pt-6 drop-shadow-md'>
            <div onClick={() => setMenu("sertifikasi")} className={` h-10 hover:bg-white hover:text-green-500 cursor-pointer flex ${hovering ? "rounded-md w-full  " : "rounded-full w-10"}  pt-2 ${type === "sertifikasi" ? "bg-white text-green-500" : "text-white"} mb-4`}>
                <div className="pl-[8px]">
                    <FontAwesomeIcon className="text-xl mt-[2px]" icon={faChalkboardUser}/>
                </div>
                { hovering ? <div className="ml-2 text-center "> Sertifikasi </div> : "" }
            </div>
            <div onClick={() => setMenu("umum")} className={` h-10 hover:bg-white hover:text-green-500 cursor-pointer  flex ${hovering ? "rounded-md w-full  " : "rounded-full w-10"}  pt-2 ${type === "umum" ? "bg-white text-green-500" : "text-white"} mb-4`}>
                <div className="pl-[8px]">
               
                 <FontAwesomeIcon className="text-xl" icon={faUserGroup}/>
                </div>
               
                 { hovering ? <div className="ml-2 text-center "> Umum </div> : "" }
            </div>
            <div onClick={() => setMenu("kedinasan")} className={` h-10 hover:bg-white hover:text-green-500 cursor-pointer  flex ${hovering ? "rounded-md w-full  " : "rounded-full w-10"}  pt-2 ${type === "kedinasan" ? "bg-white text-green-500" : "text-white"} mb-4`}>
                <div className="pl-[8px]">
            
                <FontAwesomeIcon className="text-xl" icon={faIdCard}/>
                </div>
            
                { hovering ? <div className="ml-2 text-center "> Kedinasan </div> : "" }
            </div>
            <div onClick={() => setMenu("rekanan")} className={` h-10 hover:bg-white hover:text-green-500 cursor-pointer  flex ${hovering ? "rounded-md w-full  " : "rounded-full w-10"}  pt-2 ${type === "rekanan" ? "bg-white text-green-500" : "text-white"} mb-4`}>
                <div className="pl-[10px]">
            
                <FontAwesomeIcon className="text-xl" icon={faUser}/>
                </div>
            
                { hovering ? <div className="ml-2 text-center "> Rekanan </div> : "" }
            </div>
            <div onClick={() => setMenu("pkl")} className={` h-10 hover:bg-white hover:text-green-500 cursor-pointer  flex ${hovering ? "rounded-md w-full  " : "rounded-full w-10"}  pt-2 ${type === "pkl" ? "bg-white text-green-500" : "text-white"} mb-4`}>
                <div className="pl-[8px]">
                 <FontAwesomeIcon className="text-xl" icon={faSchool} />
               
                </div>
            
                { hovering ? <div className="ml-2 text-center "> PKL </div> : "" }
            </div>
        </div>
    )
}

const DefaultForm = ({ rombongan, handleSelect, pegawai, menu, setRombongan, handleForm, submit, form, loader, modePeserta, setModePeserta, id_card, multiple_id_card, deleteIDCard, dataRegister,handleScan, setModeRekanan, modeRekanan, cariMitra }) => {
    const [strMitra, setStrMitra] = useState("");
    const [isLoading, setLoading] = useState(false);
    const handleEnterNomorMitra = async (e) =>{
        if (e.key === "Enter") {
            setLoading(true)
            await cariMitra(strMitra);
            setLoading(false)
        }
        
    }
    const handleFormMitra = (o) => {
        setStrMitra(o.target.value);
    }
    return (
        <div className="grid grid-cols-3 gap-3 p-6  pt-4">
                                
                                <div>
                                    <div>
                                    {
                                        
                                        (menu === "sertifikasi") ?
                                            <div className="grid grid-cols-2 gap-2 mb-4">
                                                <button onClick={() => setModePeserta(false)} className={` ${modePeserta ? "bg-gray-500 hover:bg-gray-400 active:bg-gray-600" : "bg-teal-500 hover:bg-teal-400 active:bg-teal-600"}  text-white rounded-md shadow-md p-2`}>BARU</button>
                                                <button onClick={() => setModePeserta(true)} className={` ${modePeserta ? "bg-teal-500 hover:bg-teal-400 active:bg-teal-600" : "bg-gray-500 hover:bg-gray-400 active:bg-gray-600"}  text-white rounded-md shadow-md p-2`}>PESERTA SERTIFIKASI</button>
                                            </div>
                                        :
                                        (menu === "rekanan") ? 
                                            <div className="grid grid-cols-2 gap-2 mb-4">
                                                <button onClick={() => setModeRekanan(false)} className={` ${modeRekanan ? "bg-gray-500 hover:bg-gray-400 active:bg-gray-600" : "bg-teal-500 hover:bg-teal-400 active:bg-teal-600"}  text-white rounded-md shadow-md p-2`}>BARU</button>
                                                <button onClick={() => setModeRekanan(true)} className={` ${modeRekanan ? "bg-teal-500 hover:bg-teal-400 active:bg-teal-600" : "bg-gray-500 hover:bg-gray-400 active:bg-gray-600"}  text-white rounded-md shadow-md p-2`}>REKANAN SERTIFIKASI</button>
                                            </div>
                                        :
                                            null
                                        
                                    }

                                    {
                                        (modePeserta) ? 
                                            <div className="pt-4">
                                                <div className="text-lg text-center"> Mohon Scan Kartu Registrasi / Ujian</div>
                                                <div className="flex justify-center">    
                                                    <div className="w-24 mt-2">      
                                                        <QR />
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        (modeRekanan) ?
                                            <div className="pt-4">
                                                <div className="text-md mb-2"> Mohon Masukan Nomor Mitra</div>
                                                <div className="flex">
                                                    <input type="text" placeholder="Nomor Mitra"  onChange={ handleFormMitra } onKeyPress={ handleEnterNomorMitra } className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="nomor_mitra" />
                                                    <div className="pl-2">
                                                    {
                                                        isLoading ? 
                                                        <div className="pt-1">
                                                            <svg role="status" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                            </svg>
                                                        </div>
                                                        
                                                        :
                                                        
                                                        <button onClick={() => handleEnterNomorMitra({ key: "Enter" })} className={` ${modeRekanan ? "bg-teal-500 hover:bg-teal-400 active:bg-teal-600" : "bg-gray-500 hover:bg-gray-400 active:bg-gray-600"}  text-white rounded-md shadow-md p-2`}>CARI</button>
                                                    }
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        :
                                        null
                                    }
                                
                                    <div className={(modePeserta || modeRekanan) ? "hidden" :""}>
                                        <div className="text-md mb-2">Nama <span className="text-red-500">*</span></div>
                                        <input type="text" placeholder="Nama" value={ form.nama } onChange={ handleForm } className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="nama" />
                                        <div className="text-md mb-2 mt-3">Asal/Instansi <span className="text-red-500">*</span></div>
                                        <textarea  type="text" placeholder="Asal" value={ form.asal } onChange={ handleForm } className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="asal" />
                                        <div className="text-md mb-2 mt-3">No HP</div>
                                        <input type="text" placeholder="No HP" value={ form.no_hp } onChange={ handleForm } className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="no_hp"  />
                                       
                                        {
                                            menu === "umum" || menu === "rekanan" || menu === "kedinasan" ?
                                            <div>
                                                <div className="text-md mb-2 mt-3">Keperluan</div>
                                                <input type="text" onChange={ handleForm } placeholder="Keperluan" value={ form.keperluan } className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="keperluan" />
                                                <AutoComplete Data={pegawai} onSelect={ handleSelect } Title="Bertemu" Name="bertemu" Placeholder="Bertemu" classDropdown="-mt-60"/>

                                            </div>
                                            : 
                                            null
                                        }
                                        {
                                             rombongan === true ?
                                             <div>
                                                
                                             {
                                                multiple_id_card.length > 0 ?
                                                <div>
                                                    <div className="flex mt-4 border-dashed border-2 border-gray-400 rounded-md p-2">
                                                        <div className="w-10"><QR /></div>
                                                        <div className="pl-4 pt-2 italic text-center "> 
                                                                <span>Mohon Scan {multiple_id_card.length - parseInt(form.jumlah_orang)} ID Card { menu }</span>
                                                        </div>
                                                    </div>
                                                    <div className="pl-4 mb-2 pt-2 text-center "> 
                                                        List ID Card
                                                    </div>
                                                    <center>
                                                    
                                                    {
                                                        multiple_id_card.map((x, i) => {
                                                            return (
                                                                <span key={i} className="bg-blue-500 text-white pl-2 rounded-md pr-2 mr-2 mb-2">
                                                                    { x } &nbsp;
                                                                    <FontAwesomeIcon className="text-red-600 text-md" onClick={ () => deleteIDCard(i) } icon={faTimes} />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    </center>
                                                </div>
                                                
                                                : 
                                                <div className="flex mt-4 border-dashed border-2 border-gray-400 rounded-md p-2">
                                                    <div className="w-10"><QR /></div>
                                                    <div className="pl-4 pt-2 italic text-center "> 
                                                            <span>Mohon Scan { form.jumlah_orang } ID Card { menu }</span>
                                                    </div>
                                                </div>
                                            }
                                             </div>
                                             
                                             : 
                                             <div className="flex mt-4 border-dashed border-2 border-gray-400 rounded-md p-2">
                                                <div className="w-10"><QR /></div>
                                                {
                                                    id_card !== "" ? 
                                                        
                                                    <div className="pl-4 pt-2 italic text-center "> { id_card }</div>
                                                    : 
                                                    
                                                    <div className="pl-4 pt-2 italic text-center "> Mohon Scan 1 ID Card { menu }</div>   
                                                }
                                            </div>
                                        }
                                    <div className="flex mt-3">
                                        <div className="text-md">Rombongan</div>
                                        <div className="pl-2">
                                            <Switch
                                                checked={rombongan}
                                                onChange={setRombongan}
                                                className={`${
                                                rombongan ? 'bg-blue-600' : 'bg-gray-200'
                                                } relative inline-flex items-center h-6 rounded-full w-11`}
                                            >
                                                <span className="sr-only">Enable notifications</span>
                                                <span
                                                className={`${
                                                    rombongan ? 'translate-x-6' : 'translate-x-1'
                                                } inline-block w-4 h-4 transform bg-white rounded-full`}
                                                />
                                            </Switch>
                                        </div>

                                        
                                    </div>
                                    {
                                        (rombongan) ? 
                                        <div>
                                            <div className="text-md mb-2 mt-3">Jumlah Orang</div>
                                            <input type="number" placeholder="Jumlah Orang" value={ form.jumlah_orang } onChange={ handleForm } className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md " name="jumlah_orang" />
    
                                        
                                           
                                        </div>
                                        :
                                        null
                                    } 
                                        <div className="mt-4">
                                            <button onClick={submit} disabled={ loader } className={`bg-emerald-600 ${loader ? "opacity-50 cursor-wait" : "hover:bg-emerald-500 active:bg-emerald-700"}  text-white w-full rounded-md p-2 text-white`}>SIMPAN</button>
                                        </div>
                                    </div>       
                                </div>


                                </div>
                                <div className="col-span-2">
                                    <TablePeserta DataRender={ dataRegister } />
                                </div>
                        </div>
    )
}

class Register extends Component{
    constructor(props) {
        super(props);
        this.state = {
            form: {
                nama: "",
                no_hp: "",
                asal: "",
                jumlah_orang: 0,
                tgl_awal: "",
                tgl_akhir: "",
                bertemu: "",
                keperluan: ""
            },
            rombongan: false,
            menu: "sertifikasi",
            hovering: false,
            id_card: "",
            multiple_id_card: [],
            loader: false,
            modePeserta: false,
            pegawai: [],
            permintaan: [],
            dataRegister: [],
            modeRekanan: false
        }
    }

    componentDidMount = () => {
        const self = this;
        getPegawai().then(res => {
            if (res.status === 200) {
               self.setState({
                pegawai: res.message
            })  
            }else{
                self.handleCatch(res)

            }
           
        })
        this.reqDataRegister("sertifikasi")
      
    }
    setModeRekanan = (toggle) => {
    
        if (toggle) {
            
            this.setState({
                modeRekanan: toggle
            })  
        }else{
            let form = this.state.form;
            for(let f in form){
                form[f] = (f === "jumlah_orang") ? 0 : ""
            }
            let bertemu = document.getElementById("ac-form");
            if (bertemu) {
                bertemu.value = ""
            }
            this.setState({
                modeRekanan: toggle,
                form: form
            })
        }
    }
    handleForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let form = this.state.form;
        form[name] = value;
        this.setState({
            form: form
        })
    }
    setRombongan = () => {
        this.setState({
            rombongan: !this.state.rombongan
        })
    }
    setHovering = (state) => {
        this.setState({
            hovering: state
        })
    }
    setMenu = (str) => {
        let form = this.state.form;
        for(let f in form){
            form[f] = (f === "jumlah_orang") ? 0 : ""
        }
        let bertemu = document.getElementById("ac-form");
        if (bertemu) {
            bertemu.value = ""
        }
        this.setState({
            form: form,
            menu: str,
            modePeserta: false,
            modeRekanan: false,
            id_card: "",
            multiple_id_card: []
        }, () => {
            if (str === "pkl") {
                const self = this;
                self.reqPermintaan();
            }else{
               const self = this;
               self.reqDataRegister()
            }
        })
    }
    handleCatch = (e) => {
        console.log(e)
        if (e.status === 401) {
            this.props.meIsLogin();
        }
    } 
    reqDataRegister = () => {
        const str = this.state.menu
        const self = this;
        getDataRegister({ tipe: getTipeData(str) })
                .then((res) => {
                    if (res.status === 200) {
                        self.setState({
                            dataRegister: res.message.reverse().map((x,i) => {
                                            x.index = i;
                                            return x
                                        })
                        })
                    }else{
                        self.handleCatch(res)
                    }          
                })
                
    }
    reqPermintaan = () => {
        const self = this;
        getPermintaan().then(res => {
                    if (res.status  === 200) {
                        self.setState({
                            permintaan: res.message.reverse().map((x, index) => ({ ...x, index: index, tgl_awal: x.tgl_awal.split("-").reverse().join("-"), tgl_akhir: x.tgl_akhir.split("-").reverse().join("-") }) )
                        })   
                    }else{
                        self.handleCatch(res)

                    }
                })
    }
    setModePeserta = (bool) => {
        this.setState({
            modePeserta: bool
        })
    }
    submit = () => {
        const rombongan = this.state.rombongan;
        let form      = this.state.form;
        const menu      = this.state.menu;
        // jika pkl insert to permintaan and then insert to register
      
            // jika bukan pkl maka langsung insert ke register
            // cek rombongan atau tidak
            if (rombongan === false) {
                const id_card = this.state.id_card;
                form.id_card = id_card
                const check = isRequired(form, ["nama", "asal", "id_card"])
                if (check.status === false) {
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={check.message} />
                    })  
                }else{
                    this.setState({
                        loader: true
                    }, () => {
                        const self = this;
                        add({
                            nama: form.nama, 
                            asal: form.asal, 
                            bertemu: form.bertemu, 
                            jenis: getTipeData(menu), 
                            id_card: id_card, 
                            id_rombongan: Date.now(), 
                            no_hp: form.no_hp, 
                            keperluan: form.keperluan, 
                            id_permintaan: "" 
                        }).then((res) => {
                            if (res.status === 200) {
                                toast.custom((t) => {
                                    return <ToastComponent param={t} message={res.message} bg="green" />
                                })  
                            }else{
                                toast.custom((t) => {
                                    return <ToastComponent param={t} message={res.message} bg="red" />
                                })
                                self.handleCatch(res)

                            }
                            self.setState({
                                loader: false,
                                form: resetValue(form, ["ac-form"]),
                                id_card: "",
                                multiple_id_card: []
                            },() => {
                                self.reqDataRegister();
                            })
                        })
                    })  
                }
            }else if(rombongan === true){
                // jika rombongan add multiple
                // jumlah peserta harus >= 1
                const self = this
                let isValid = true;
                if (parseInt(form.jumlah_orang) < 1) {
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="Jumlah Peserta Harus Lebih dari sama dengan 1" />
                    })  
                    isValid = false;
                }
                // harus mengisi id card sesuai dengan jumlah peserta
                let multiple_id_card = this.state.multiple_id_card;
                if (form.jumlah_orang != multiple_id_card.length) {
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="Jumlah Peserta dengan Jumlah ID Card Tidak Sama" />
                    })  
                    isValid = false;
                }
                // check required data
                const check = isRequired(form, ["nama", "asal"])
                if (check.status === true) {
                    // jika valid
                    if (isValid) {   
                        const now = Date.now();
                        // membuat block baru sesuai jumlah peserta
                        let newForm = [];
                        for (let j = 0; j < form.jumlah_orang; j++) {
                            newForm.push({
                                nama: form.nama, 
                                asal: form.asal, 
                                bertemu: form.bertemu, 
                                jenis: getTipeData(menu), 
                                id_card: multiple_id_card[j], 
                                id_rombongan: now, 
                                no_hp: form.no_hp, 
                                keperluan: form.keperluan, 
                                id_permintaan: "" 
                            })
                        }   
                        this.setState({
                            loader: true
                        }, () => {
                            addMultiple(newForm).then((res) => {
                                if (res.status === 200) {
                                    toast.custom((t) => {
                                        return <ToastComponent param={t} message={res.message} bg="green" />
                                    })  
                                }else{
                                    toast.custom((t) => {
                                        return <ToastComponent param={t} message={res.message} bg="red" />
                                    })
                                    self.handleCatch(res)

                                }
                                self.setState({
                                    loader: false,
                                    form: resetValue(form, ["ac-form"]),
                                    id_card: "",
                                    multiple_id_card: []
                                }, () => {
                                    self.reqDataRegister();

                                })
                            })
                        })
                        
                    }
                }else{
                    toast.custom((t) => {
                        return <ToastComponent param={t} message={check.message} />
                    })  
                }
            }
        
    }

    handleScan = (data) =>{
        // toast.custom((t) => {
        //     return <ToastComponent param={t} message={"Scan Berhasil Text : " + data} bg="green" />
        // })  
        // menu
        const menu = this.state.menu;
        const rombongan = this.state.rombongan;
        const jumlah_orang= this.state.form.jumlah_orang;
        const modePeserta = this.state.modePeserta;
        let form = this.state.form;
     //   const menu = this.state.menu;
        const self = this;
        if (modePeserta && menu !== "pkl") {
            
            cariPeserta({ content: data })
            .then((res) => {
                if (res.status === 200) {
                    let obj = res.message;
                    if (obj.length > 0) {
                        form['nama'] = obj[0]['nama'];
                        form['asal'] = obj[0]['asal'];
                        self.setState({
                            modePeserta: false,
                            form: form
                        })
                    }else{
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="Kartu Tidak Ditemukan" />
                        })                  
                    }
                }else{
                    toast.custom((t) => {
                        return <ToastComponent param={t} message="Gagal Menghubungi Server Portal" />
                    })           
                    self.handleCatch(res)

                }
            })
        }else{

        // check kartu
        const spl = data.split("-");
        // if (tipeData()[spl[0]] === undefined) {
        //     toast.custom((t) => {
        //         return <ToastComponent param={t} message="Kartu Ditolak" />
        //     })  
        // }else if (tipeData()[spl[0]] !== menu) {
        //     toast.custom((t) => {
        //         return <ToastComponent param={t} message={"Mohon Gunakan ID Card " + menu} />
        //     })  
        // }else{
            this.setState({
                loader: true
            }, () => {
                getKartu({ id_card: data }).then(res => {
                    if (res.status === 200) {
                        if (res.message.length > 0) {
                                toast.custom((t) => {
                                    return <ToastComponent param={t} message={"ID Card Masih Digunakan Oleh " + res.message[0]['nama'] + "(" + res.message[0]['asal'] + ")"} />
                                })  
                        }else{
                            if (rombongan || menu === "pkl") {
                                let multiple_id_card = self.state.multiple_id_card
                                if (multiple_id_card.length  === jumlah_orang && menu !== "pkl") {
                                    toast.custom((t) => {
                                        return <ToastComponent param={t} message="ID Card sudah terpenuhi" />
                                    })
                                }else{
                                    multiple_id_card = multiple_id_card.filter((x) => {
                                        return x !== data
                                    })
                                    multiple_id_card.push(data);
                                    self.setState({
                                        multiple_id_card: multiple_id_card
                                    })
                                }
                                    
                            }else{    
                                self.setState({
                                    id_card: data
                                })
                            }
                        }
                    }else{
                        toast.custom((t) => {
                            return <ToastComponent param={t} message={res.message} />
                        })  
                        self.handleCatch(res)

                    }
                    self.setState({
                        loader: false
                    })
                })
            })
        // }
        }
    }
    handleError = (err) => {
        console.error(err)
    }
    deleteIDCard = (index) => {
        let multiple_id_card = this.state.multiple_id_card;
        multiple_id_card.splice(index, 1);
        this.setState({
            multiple_id_card: multiple_id_card
        })
    }
    handleSelect = (x) => {
        document.getElementById("ac-form").value = x.title;
        let form = this.state.form;
        form['bertemu'] = x.id;
        this.setState({
            form: form
        })
    }
    submitPkl = async (state) => {
        let newForm = [];
        let multiple_id_card = this.state.multiple_id_card;
        const id_rombongan = Date.now();
        for(let s of state.peserta){
            // nama, no_hp, asal, tgl_awal, tgl_akhir
            newForm.push({
                id_permintaan: (s.id_permintaan) ? s.id_permintaan : "",
                nama: s.nama,
                no_hp: state.no_hp,
                asal: state.asal,
                tgl_awal: state.tgl_awal,
                tgl_akhir: state.tgl_akhir,
                id_rombongan: id_rombongan,
                id_card: multiple_id_card[s.index]
            })
        }
        const self = this;
        //console.log(newForm)
       return await addPkl({ data: newForm }).then((res) => {
            if (res.status === 200) {
                toast.custom((t) => {
                    return <ToastComponent param={t} message="PKL Berhasil Disimpan" bg="green" />
                })
                self.setState({
                    multiple_id_card: []
                }, () => {
                    self.reqPermintaan();

                })
            }else{
                console.log(res);
                toast.custom((t) => {
                    return <ToastComponent param={t} message="PKL Gagal Disimpan" bg="red" />
                })  
                self.handleCatch(res)

            }
        })
    }
    hapusPermintaan = (id_permintaan) => {
        const self = this;
        swal({
            title: "Apakah Anda Yakin?",
            text: "Aksi Ini Tidak Dapat Dikembalikan",
            icon: "warning",
            buttons: ["Tidak", "Yakin"],
            dangerMode: true,
        
        })
        .then((willDelete) => {
            if (willDelete) {
                hapus({ id_permintaan: id_permintaan }).then((res) => {
                    if (res.status === 500) {
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="PKL Gagal Dihapus" bg="red" />
                        })  
                        self.handleCatch(res)

                    }else{
                        toast.custom((t) => {
                            return <ToastComponent param={t} message="PKL Berhasil Dihapus" bg="orange" />
                        })  
                        self.reqPermintaan();

                    }
                })
            } 
        });
    }
    updateMultipleIdCard = (id_card) => {
        this.setState({
            multiple_id_card: id_card
        })
    }
    cariMitra = async (str) => {
        const dataMitra = await apiSimtra().then(res => {
            return res;
        })

        if (dataMitra.status === 200) {
            const data = dataMitra.data;
            const cari = str.toLowerCase();
            const cariMitra = data.filter((x) => {
                return x.nomor_mitra.toLowerCase() === cari;
            })    
            if (cariMitra.length === 0) {
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Nomor Mitra Tidak Diketahui" bg="red" />
                })    
                
                return false
            }else{
                let form = this.state.form;
                form['nama'] = "";
                form['asal'] = cariMitra[0]['nama_instansi'] + " " + cariMitra[0]['alamat_instansi'];
                form['keperluan'] = "Sertifikasi"
           
                this.setState({
                    form: form,
                    modeRekanan: false
                }, () => {
                    let pegawai = this.state.pegawai;
                    let cariHartono =  pegawai.filter((x) => {
                        return x.title === "HARTONO"
                    })
                    if (cariHartono.length > 0) {
                        this.handleSelect(cariHartono[0])
                        
                    }
                })
                return true
            }
        }else{
            
            toast.custom((t) => {
                return <ToastComponent param={t} message="Gagal Menghubungi Server SIMTRA" bg="red" />
            })  
            return true
        }
        
    }
    render(){
        const { rombongan, menu, hovering, loader, form, modePeserta, id_card, multiple_id_card, pegawai, permintaan, dataRegister, modeRekanan } = this.state
        const { setRombongan, setHovering, setMenu, handleForm, submit, setModePeserta, handleError, handleScan, deleteIDCard, handleSelect, submitPkl, handleEditPKL, hapusPermintaan, updateMultipleIdCard, setModeRekanan, cariMitra } = this
        return (
            <div className={ loader ? "cursor-wait" : "" } >
                <BarcodeReader
                    onError={handleError}
                    onScan={handleScan}
                />

                <Toaster position="bottom-right"/>
                <DetailSideBar type={menu} hovering={hovering} setHovering={ setHovering } setMenu = {setMenu} />
              
                <div className="bg-gray-100 p-5 pt-3 min-h-screen">
                    <div className="pl-16">
                        <div className="">
                            <div className="bg-white  rounded-md shadow-md">
                                <div className="bg-gradient-to-r from-teal-500 to-emerald-500 p-4 rounded-t-lg">
                                    <div className="text-md text-white">Register {menu}</div>
                                </div>
                                
                                {
                                    
                                    menu === "pkl" ?
                                        <div>
                                            <PklForm 
                                                multiple_id_card={multiple_id_card} 
                                                permintaan={ permintaan } 
                                                submitPkl={ submitPkl } 
                                                 hapusPermintaan={ hapusPermintaan }
                                                 updateMultipleIdCard= {updateMultipleIdCard}
                                                 handleScan={handleScan}
                                            />
                                        </div>
                                    : 
                                   
                                    <div>
                                        <DefaultForm 
                                        pegawai={ pegawai }
                                        handleSelect ={ handleSelect }
                                        deleteIDCard={ deleteIDCard } 
                                        multiple_id_card= { multiple_id_card } 
                                        id_card = { id_card } 
                                        modePeserta={modePeserta} 
                                        setModePeserta={setModePeserta} 
                                        form={form} 
                                        loader={loader} 
                                        rombongan ={rombongan}
                                        menu={menu} 
                                        setRombongan={setRombongan} 
                                        handleForm={ handleForm } 
                                        dataRegister = {dataRegister}
                                        submit = {submit}
                                        handleScan={handleScan}
                                        setModeRekanan ={setModeRekanan}
                                        modeRekanan={modeRekanan}
                                        cariMitra={cariMitra}
                                        />
                                    
                                    </div>
                                   
                                }
                               
                            </div>

                         
                        </div>

                    </div>
                                
                    </div>
               
            </div>
        )
    }
}

const HandleRegister = () => {
    return (
        <Auth.AuthConsumer>
            {
                ({ meIsLogin }) => {
                    return (
                        <Register meIsLogin={meIsLogin} />

                    )
                }
                
            }
        </Auth.AuthConsumer>
    )
}
export default HandleRegister
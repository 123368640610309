import axios from 'axios';
import { backEndUrl } from '../config/Config';

export const setKembali = async ({ kartu }) => {
    let form = new FormData();
    form.set("kartu", kartu)

    return await axios.post(backEndUrl() + "api/setKembali", form, { withCredentials: true })
    .then((result) => {
        return result.data;   
    }).catch((err) => {
        return {
            status: 500,
            message: err.response
        }
    });
}
export const getDataRegisterBw = async ({ tgl_awal, tgl_akhir }) => {
    let param = "";
    if (tgl_awal && tgl_awal !== "") {
        param += "tgl_awal=" + tgl_awal;
    }

    if ((tgl_awal && tgl_akhir ) && (tgl_awal !== "" && tgl_akhir !== "")) {
        param += "&";
    }

    if (tgl_akhir && tgl_akhir !== "") {
        param += "tgl_akhir=" + tgl_akhir
    }
    console.log(param)
    return await axios.get(backEndUrl() + "api/getDataRegisterBw?" + param, { withCredentials: true })
    .then((result) => {
        return result.data;   
    }).catch((err) => {
        return {
            status: 500,
            message: err.response
        }
    });
}

export const getDataRegister = async ({ tipe }) => {
    let form = new FormData();
    form.set("tipe", tipe);

    return await axios.post(backEndUrl() + "api/getDataRegister", form, { withCredentials: true })
    .then((result) => {
        return result.data;   
    }).catch((err) => {
        return {
            status: 500,
            message: err.response
        }
    });
}
export const add = async ({ nama, asal, bertemu, jenis, id_card, id_rombongan, no_hp, keperluan, id_permintaan }) => {
    let type = "INSERT";
    let form = new FormData();
        form.set("nama", nama);
        form.set("asal", asal);
        form.set("bertemu", bertemu);
        form.set("jenis", jenis);
        form.set("id_card", id_card);
        form.set("id_rombongan", id_rombongan);
        form.set("no_hp", no_hp);
        form.set("keperluan", keperluan);
        form.set("id_permintaan", id_permintaan);
        form.set("type", type)
    return await axios.post(backEndUrl() + "api/handleRegister", form, { withCredentials: true })
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}
export const set = async ({ nama, asal, bertemu, jenis, id_card, id_rombongan, no_hp, keperluan, id_permintaan, id_register }) => {
    let type = "UPDATE";
    let form = new FormData();
        form.set("nama", nama);
        form.set("asal", asal);
        form.set("bertemu", bertemu);
        form.set("jenis", jenis);
        form.set("id_card", id_card);
        form.set("id_rombongan", id_rombongan);
        form.set("no_hp", no_hp);
        form.set("keperluan", keperluan);
        form.set("id_permintaan", id_permintaan);
        form.set("id_register", id_register);
    return await axios.post(backEndUrl() + "api/handleRegister", form, { withCredentials: true })
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}
export const addMultiple = async (data) => {
    let type = "ADD_MULTIPLE";
    let form = new FormData();
        form.set("data", JSON.stringify(data));
        form.set("type", type);
    return await axios.post(backEndUrl() + "api/handleMultipleRegister", form, { withCredentials: true })
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}

export const cariPeserta = async ({ content }) => {
    let form = new FormData();
        form.set("token", "6F2BEBF6E4BC5C2F6431A7EE39CFF")
        form.set("content", content);
    return await axios.post("https://portal.migascepu.id/sertifikasi/api/cariPeserta", form)
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}
export const getPegawai = async () => {
    
    return await axios.get(backEndUrl() + "api/getPegawai", { withCredentials: true })
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}

export const getGroupChart = async () => {
    // api/getGroupChart
    return await axios.get(backEndUrl() + "api/getGroupChart", { withCredentials: true })
    .then((result) => {
        return result.data;   
    }).catch((err) => {
        return {
            status: 500,
            message: err.response
        }
    });
}
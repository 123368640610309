import  { Component } from 'react';
import {  faChalkboardUser, faUserGroup, faIdCard, faUser, faSchool } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDataRegisterBw, getGroupChart } from '../api/Register';
import Datatable from '../components/Datatable';
import { tipeData } from '../config/Config';
import { DMYHIS, getLabelsMonth, now, listBulan } from '../api/Value'
import  Line  from './Line';
import Pie from './Pie';

const TablePeserta = ({  DataRender }) => {
 
 const TheadComponent = ({ sortData }) => {
   return (
     <thead>
       <tr>
           <td className=" border-2  border-gray-300 p-2 w-20 text-center" >No</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Nama</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Asal</td>
           <td className=" border-2 border-gray-300 p-2 text-center">No HP</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Jenis</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Absen Masuk / Absen Keluar</td>
           <td className=" border-2 border-gray-300 p-2 text-center">Tgl Daftar / Tgl Selesai</td>
           
         
       </tr>
     </thead>
   )
 }
 return (
   <div>
       <Datatable 
            Export={BtnExport}
            Head={TheadComponent}
            Data={DataRender}
            Entries={[]}
            Body={(row, index) => {
                const mod = (index%2 == 0) ? " bg-gray-100 " : "";
     
           return (
             <tr key={ index }>
               <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod}>
                   { row.index + 1 } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top" + mod}>
                   { row.nama } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top" + mod}>
                   { row.asal } 
               </td>
               <td className={"border-2 border-gray-300 p-2 align-top" + mod}>
                   { row.no_hp } 
               </td>
               <td className={"border-2 border-gray-300 p-2 text-center align-top" + mod}>
                    { row.jenis }
               </td>
               <td className={"border-2 border-gray-300 text-center p-2 align-top" + mod}>
                   <span className="text-green-800">{ row.check_in }</span>  
                    <hr />
                    <span className="text-red-800">{ row.check_out }</span>
                    
                </td>
               <td className={"border-2 border-gray-300 text-center p-2 align-top" + mod}>
                    <span className="text-green-800">{ row.date_masuk }</span>  
                    <hr />
                    <span className="text-red-800">{ row.date_keluar }</span>
                    
               </td>
             </tr>
           )
         }}
       />
   </div>
 )
}
class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            tgl_awal: "",
            tgl_akhir: "",
            dataBw: [],
            dataChart: {
               
                forChart: [],
                forPieChart: [0,0,0,0,0]
            },
            maxMonth: 0,
            labels: []
        }
    }
    componentDidMount = () => {
        this.requestDataBw();
       /* const self = this;
        
        getGroupChart().then(res => {
            if (res.status === 200) {
               // console.log(res)
                let forChart = {}
                let forPieChart = [0, 0, 0, 0, 0];
                let maxMonth = 0;
                for(let f of res.message.forChart){
                    if (forChart[f.jenis]) {
                        const latest = forChart[f.jenis][parseInt(f.bulan) - 1];
                        forChart[f.jenis][parseInt(f.bulan) - 1] = (latest) ? latest + 1: 1;
                    }else{
                        forChart[f.jenis] = [];
                        forChart[f.jenis][parseInt(f.bulan) - 1] = 1;
                    }
                    forPieChart[parseInt(f.jenis) - 1] += 1;
                }   
                for(let d in forChart){
                    if (forChart[d].length > maxMonth) {
                        maxMonth = forChart[d].length
                    }
                }
                
                //console.log(maxMonth)
                self.setState({
                    dataChart: {
                     
                        forChart: forChart,
                        forPieChart: forPieChart
                    },
                    maxMonth: maxMonth
                })
            }
        })
        */
    }
    requestDataBw = () => {
        
        const self= this;
        const req = { tgl_awal: this.state.tgl_awal, tgl_akhir: this.state.tgl_akhir };
       // console.log(req)
        getDataRegisterBw(req)
        .then(res => {
         //   console.log(res)
         const type = tipeData();
            if (res.status === 200) {
                let forChart    = {};
                let forPieChart = [0, 0, 0, 0, 0]
                let maxMonth    = 0;
                let minTgl      = now();
                let maxTgl      = now();
                const namaBulan = listBulan;
                const dataBw = res.message.map((x,i) => {
                        x.index = i
                        const check_in = x.check_in.split(" ")[0];
                        if (check_in < minTgl) {
                            minTgl = check_in;
                        }
                        if (check_in > maxTgl) {
                            maxTgl = check_in;
                        }
                        forPieChart[parseInt(x.jenis)- 1] += 1; 
                        const bulan     = x.check_in.split("-")[1];
                        const target    =  namaBulan[parseInt(bulan) - 1] + "_" + check_in.split("-")[0];
                        if (forChart[x.jenis]) {
                            if (forChart[x.jenis][target]) {
                                forChart[x.jenis][target] += 1
                            }else{
                                forChart[x.jenis][target]   = 1;
                            }
                        }else{
                            forChart[x.jenis] = {};
                            if (forChart[x.jenis][target]) {
                                forChart[x.jenis][target] += 1
                            }else{
                                forChart[x.jenis][target]   = 1;
                            }
                        }
                        
                        x.jenis = type[x.jenis]
                        x.date_masuk  = DMYHIS(x.date_masuk).split(" ")[0]
                        x.date_keluar = DMYHIS(x.date_keluar).split(" ")[0]
                        x.check_in      = (x.check_in) ? DMYHIS(x.check_in) : "";
                        x.check_out     = (x.check_out) ? DMYHIS(x.check_out) : "";
                        return { 
                            index: x.index,
                            nama: x.nama,
                            no_hp: x.no_hp,
                            asal: x.asal,
                            jenis: x.jenis,
                            date_masuk: x.date_masuk,
                            date_keluar: x.date_keluar,
                            check_in: x.check_in,
                            check_out: x.check_out
                        }
                    })
                    const labels = getLabelsMonth(minTgl, maxTgl);
                    let newChart = [];
              

                    
                 
                self.setState({
                    dataBw: dataBw,
                    dataChart: {
                        
                        forChart: forChart,
                        forPieChart: forPieChart
                    },
                    maxMonth: maxMonth,
                    labels: labels
                })
            }
        })
    }
    handleBw = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        let state = this.state;
        state[name] = value;
        this.setState({
            tgl_awal: state.tgl_awal,
            tgl_akhir: state.tgl_akhir
        }, () => {
            this.requestDataBw()
        })
    }
    render(){
        const date = new Date();
        const { dataBw, dataChart, maxMonth, tgl_awal, tgl_akhir, labels } = this.state;
     
        const { handleBw } = this;
        let graphTahun = "";
        if (tgl_awal === "" && tgl_akhir === "") {
            graphTahun = "Hari Ini";
        }else{
            if (tgl_awal !== "" && tgl_akhir === "") {
                graphTahun = "Lebih Dari " + tgl_awal.split("-").reverse().join("/")
            }else if (tgl_awal === "" && tgl_akhir !== "") {
                graphTahun = "Kurang Dari " + tgl_akhir.split("-").reverse().join("/")
            }else{
                graphTahun = tgl_awal.split("-").reverse().join("/")
                graphTahun += " s.d ";
                graphTahun += tgl_akhir.split("-").reverse().join("/")
            }
        }
        return (
            <div className="p-5 bg-gray-200 min-h-screen">
                <div className="grid grid-cols-5 gap-3">
                   <MiniCard classAdd="bg-purple-500" title="Umum" number={ dataChart["forPieChart"][0] } icon={faUserGroup}/>
                   <MiniCard classAdd="bg-yellow-500" title="Kedinasan" number={ dataChart["forPieChart"][1] } icon={faIdCard}/>
                   <MiniCard classAdd="bg-green-500" title="PKL" number={ dataChart["forPieChart"][4] } icon={faSchool}/>

                   <MiniCard classAdd="bg-orange-500" title="Sertifikasi" number={ dataChart["forPieChart"][2] } icon={faChalkboardUser}/>
                   <MiniCard classAdd="bg-blue-500" title="Rekanan" number={ dataChart["forPieChart"][3] } icon={faUser}/>
                </div>
                <div className="grid grid-cols-2 gap-5">
                    <div className="bg-white shadow-md mt-3 p-5  rounded-md">
                        <Line
                            data={{
                                labels:labels,
                                datasets: [
                                        {
                                            label: 'Umum',
                                            data:  labels.map((x) => bindData(x,"1", dataChart["forChart"])),
                                            borderColor: 'rgb(168, 85, 247)',
                                            backgroundColor: 'rgb(168, 85, 247)',
                                        },
                                        {
                                            label: 'Kedinasan',
                                            data:  labels.map((x) => bindData(x,"2", dataChart["forChart"])),
                                            borderColor: 'rgb(234, 179, 8)',
                                            backgroundColor: 'rgb(234, 179, 8)',
                                        },
                                        {
                                            label: 'Sertifikasi',
                                            data:  labels.map((x) => bindData(x,"3", dataChart["forChart"])),
                                            borderColor: 'rgb(249, 115, 22)',
                                            backgroundColor: 'rgb(249, 115, 22)',
                                        },
                                        {
                                            label: 'PKL',
                                            data: labels.map((x) => bindData(x,"5", dataChart["forChart"])),
                                            borderColor: 'rgb(34, 197, 94)',
                                            backgroundColor: 'rgb(34, 197, 94)',
                                        },
                                        {
                                            label: 'Rekanan',
                                            data:  labels.map((x) => bindData(x,"4", dataChart["forChart"])),
                                            borderColor: 'rgb(59, 130, 246)',
                                            backgroundColor: 'rgb(59, 130, 246)',
                                        },

                                    ],
                                }
                            }
                            options={
                                {
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                            title: {
                                            display: true,
                                            text: 'Grafik Tamu ' + graphTahun,
                                        },
                                    },
                                }

                            }
                        />
                    </div>
                    <div className="bg-white shadow-md mt-3 p-5 rounded-md flex justify-center">
                            <div className="w-80">
                            <Pie 
                                data = {
                                     {
                                        labels: ["Umum", "Kedinasan", "Sertifikasi", "Rekanan", "PKL"],
                                        datasets: [
                                        {
                                            label: '# of Votes',
                                            data: dataChart["forPieChart"],
                                            backgroundColor:['rgb(168, 85, 247)', 'rgb(234, 179, 8)', 'rgb(249, 115, 22)', 'rgb(59, 130, 246)', 'rgb(34, 197, 94)'],
                                            borderColor: ['rgb(168, 85, 247)', 'rgb(234, 179, 8)', 'rgb(249, 115, 22)', 'rgb(59, 130, 246)', 'rgb(34, 197, 94)'],
                                            borderWidth: 1,
                                        },
                                    ],
                                    }
                                }
                            />
                            </div>
                            
                    </div>
                </div>
                <div className="bg-white mt-3 p-5 rounded-md">
                    <div>Filter Between</div>
                    <div className="flex gap-2">
                        <div className="pt-2">
                            <input type="date" onChange={ handleBw } name="tgl_awal" className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md" />
                        </div>
                        <div className="pt-2">
                            <input type="date" onChange={ handleBw } name="tgl_akhir" className="bg-gray-200 w-full p-[8px] border-2 border-gray-300 rounded-md" />
                        </div>
                    </div>
                    <br />
                    <TablePeserta DataRender={ dataBw } />

                </div>
            </div>
        )
    }
}
const BtnExport = ({ exportFile }) => {
    
    return (
        <button className="rounded-md ml-2 bg-teal-500 p-2 active:bg-teal-600 hover:bg-teal-400 text-white" onClick={() => exportFile("excel", "Laporan Tamu") }>Excel</button>
    )
}
const MiniCard = ({ classAdd,title, number, icon }) => {
    return (
        <div className={classAdd + " p-3 rounded-md text-white flex"}>
            <div className="w-3/4">
                <div className="text-lg">{title}</div>
                <div className="font-bold">{number}</div>
            </div>
            <div className="text-center w-1/4 pt-3">
                <FontAwesomeIcon className="text-3xl" icon={icon}/>
            </div>
        </div>
                    
    )
}
const bindData = (x, id,forChart) => {

    const ind = x.replaceAll(" ", "_");
    if (forChart[id]) {
        if (forChart[id][ind]) {
            return forChart[id][ind];
        }else{
            return 0
        }
        }else{
        return 0;
    }

    
}
export default Dashboard
import axios from 'axios';
import { backEndUrl } from '../config/Config';

export const getKartu = async ({ id_card }) => {
    let form = new FormData();
        form.set("kartu", id_card);
        return await axios.post(backEndUrl() + "api/getKartu", form, { withCredentials: true })
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}

export const setKembali = async ({ id_card }) => {
    let form = new FormData();
        form.set("kartu", id_card);
        return await axios.post(backEndUrl() + "api/setKembali", form, { withCredentials: true })
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}

export const setAbsen = async ({ id_card }) => {
    let form = new FormData();
        form.set("id_card", id_card);
        return await axios.post(backEndUrl() + "api/absen", form, { withCredentials: true })
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}


import axios from 'axios';
import { backEndUrl } from '../config/Config';

export const getPermintaan = async () => {
    return await axios.get(backEndUrl() + "api/getPermintaan", { withCredentials: true })
        .then((result) => {
            return result.data;   
        }).catch((err) => {
            return {
                status: 500,
                message: err.response
            }
        });
}
export const apiSimtra = async () => {
    let form = new FormData();
        form.set("token", "we6Al6KP75FVjqsNQnCIf2XRpY4myoU8FLVmUOmDrMAjKdnXdYS7kbqBEssHh2861vrgGcPG1TNX2XoOndeEeGi9ZgZVcK7Ysx2yaFpXofQzUw5jFl2M7DMHuIKe7GP5kf8nHKGW822uaYHs3rrRCK5fYNMloTEwT660ehsh4zghOjyiIRe74eBapunHgb4dmElfLpx8X2QQmEfyZZ8Zuiv5kcyvui5JhaVOtgtvyc8tzAYyodBQt6e0VJon9qvZqsjEvsrKu3iUqeHTaw7wBwFGJvAAqDnDSBWEWvs3kLH7fdK3SFlExC193TQ8qVYB69uBLWB4zO8SMjzWiqZeR4s9NXsQ0UtimHncAA74Vms6BLrINws2DT9tVJcmWHsTCk6V9F41c64z7dr4BebuCmOs3Dh9qq3UcdfGnjlZ1Uuh5P3IT2X66T6ELltdys7XmG32R5oJ7OmT9YMMra2eUxrrABn27golcAoqKHT9KYS4wt8RfvcCWxAeu2UlS6x3dxVzqzvfZUbGC5lzRPozsYrKI7r7ZevtqP5VdmzMr131C8vuhGBYx0lHK6T6ofZ1PQmHbsF3BjK1n7wyfVQMg1WbEH2p0etuugC13JaUHoPoViQ0wz1qmicP9dH6TTQk2RVavaQD5uS7KM1OxO9PrdLn0ayAuZlDco8yJxes7hXxSUwpdizr4SWQmnCB46FWVLQhxvaFYLjVUGWa7mtaihqt5C9fcquY0seLmnUJuxqQjwLI38HbyhCPItAhWeegcLJGvljOnlSNDxM40TenzOuBlfmBmb1LiaeiUBpGeqjzTlrDdUSs8dgdDEZtiVs4ADdvAHgdwyENbJy6IwuslC8jRHJqKIvNssxa53VN33VnLZOkbQa9UGruHe6CWL7H2ttK5uYxC4RKlYkzsTYrT3CSm16Ac8pYLs3yco0TlhGAOgn5ftGRmZAArVEp0nc0yjXzOHayOdrNHZxcymiaceztOWfKkvyf6uCWmSjUS8pWhoXfFfsZEiQZFRf7msdZ");
    return await axios.post("https://simtra.ppsdmmigas.id/Api_Get_Instansi_Dengan_Sertifikat_Aktif/index", form)
        .then((res) => {
            return {status: 200, data:res.data};
        }).catch((e) => {
            return {
                status: 500,
                message: e.response
            }
        });
}
export const addPkl = async ({  data }) => {
    let form = new FormData();
        form.set("data", JSON.stringify(data));
    return await axios.post(backEndUrl() + "api/handlePKL", form, { withCredentials: true })
        .then((result) => {
            return result.data;   
        }).catch((err) => {
            return {
                status: 500,
                message: err.response
            }
        });
}
export const hapus = async ({ id_permintaan }) => {
    let form = new FormData();
        form.set("id_permintaan", id_permintaan);
    return await axios.post(backEndUrl() + "api/hapusPermintaan", form, { withCredentials: true } )
        .then((result) => {
            return result.data
        })
        .catch((err) => {
            return {
                status: 500,
                message: err.response
            }
        })
}
export const add = async ({ nama, no_hp, asal, email, jenis_permintaan, id_lokasi, jumlah_orang, tgl_awal, tgl_akhir, status_humas }) => {
    let type = "INSERT";
    let form = new FormData();
    form.set("nama", nama);
    form.set("no_hp", no_hp);
    form.set("asal", asal);
    form.set("email", email);
    form.set("jenis_permintaan", jenis_permintaan);
    form.set("id_lokasi", id_lokasi);
    form.set("jumlah_orang", jumlah_orang);
    form.set("tgl_awal", tgl_awal);
    form.set("tgl_akhir", tgl_akhir);
    form.set("status_humas", status_humas);
    return await axios.post(backEndUrl() + "api/handlePermintaan", form, { withCredentials: true })
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}
export const set = async ({ nama, no_hp, asal, email, jenis_permintaan, id_lokasi, jumlah_orang, tgl_awal, tgl_akhir, status_humas, id_permintaan }) => {
    let type = "UPDATE";
    let form = new FormData();
    form.set("nama", nama);
    form.set("no_hp", no_hp);
    form.set("asal", asal);
    form.set("email", email);
    form.set("jenis_permintaan", jenis_permintaan);
    form.set("id_lokasi", id_lokasi);
    form.set("jumlah_orang", jumlah_orang);
    form.set("tgl_awal", tgl_awal);
    form.set("tgl_akhir", tgl_akhir);
    form.set("status_humas", status_humas);
    form.set("id_permintaan", id_permintaan);
    return await axios.post(backEndUrl() + "api/handlePermintaan", form, { withCredentials: true })
            .then((result) => {
                return result.data;   
            }).catch((err) => {
                return {
                    status: 500,
                    message: err.response
                }
            });
}
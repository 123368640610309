import { Component } from 'react';
import Auth from '../api/Auth';


const ConsLogin = () => {
    return (
        <Auth.AuthConsumer>
            {
                ({ verifyLogin }) => {
                    return (
                        <Login verifyLogin={ verifyLogin } />
                    )
                }
            }
        </Auth.AuthConsumer>
    )
}

class Login extends Component{
    state = {
        form: {
            username: "",
            password: ""
        },
        errorMessage: ""
    }
    handleForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let form = this.state.form;
        form[name] = value;
        this.setState({
            form: form
        })
    }
    componentDidMount = () => {

    }
    submit = () => {
        const form = this.state.form;
        let isValid= true;
        if (form.username === "") {
                
            this.setState({
                errorMessage: "Username Tidak Boleh Kosong"
            }, () => {
                setTimeout(() => {
                    this.setState({
                        errorMessage: ""
                    })
                }, 2000);
            })
            isValid = false;
        }else if (form.password === "") {
            this.setState({
                errorMessage: "Password Tidak Boleh Kosong"
            }, () => {
                setTimeout(() => {
                    this.setState({
                        errorMessage: ""
                    })
                }, 2000);
            })
            isValid = false;
        }

        if (isValid) {
            const self = this;
            this.props.verifyLogin(form).then((x) =>{ 
                if (x.status === 500) {
                        
                    self.setState({
                        errorMessage: x.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                errorMessage: ""
                            })
                        }, 2000);
                    })   
                }else{

                }
            })
        }
        //this.props.verifyLogin;
        
       // console.log(verifyLogin)
    }
    render(){
        const { handleForm, submit } = this;
        const { errorMessage }       = this.state;
        return (
            <div className="w-full h-screen bg-gradient-to-r from-emerald-500  to-blue-500">
                <div className="flex justify-center">
                    
                    <div className="bg-white rounded-md md:w-1/4 shadow-xl mt-20 p-4 pt-8 pb-8">
                        <center>
                            <img src={require("../esdm.png")} width="100" alt="" />
                            <div className="font-bold pt-3">SI BUKU TAMU</div>
                            
                        </center>
                        <div className="p-4 content-center ">
                            <center>
                            {
                                (errorMessage !== "") ? 
                                    <div className="bg-rose-500 text-white rounded-md mb-2">{ errorMessage }</div>
                                : 
                                null
                            }
                            </center>
                            <div>Username</div>
                            <input type="text" onChange={ handleForm } name="username" className="border-2 text-sm mt-2 border-gray-300 rounded-md focus:border-blue-500 w-full p-2" placeholder="Username" />
                            <br />
                            <div className="mt-2">Password</div>
                            <input type="password" onChange={ handleForm } onKeyPress={
                                (e) => {
                                    if (e.key === "Enter") {
                                        submit()
                                    }
                                }
                            } name="password" className="border-2 mt-2 text-sm border-gray-300 rounded-md w-full p-2" placeholder="Password" />
                            
                            <button onClick={ submit } className="bg-gradient-to-r from-emerald-500  to-teal-500 w-full mt-3 p-2 rounded-md text-white shadow-md hover:opacity-80 active:opacity-90">SIGN IN</button>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConsLogin;
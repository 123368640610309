import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export const Toast = ({ position="l", message, param, bg="red"  }) => {

    const animate =      (param.visible) ? 'animate-fadein' : 'transition ease-in-out opacity-0'
        
    return(
         <div className={`flex rounded-md border-${position}-8 border-${bg}-800 shadow-xl text-white bg-${bg}-600 p-3 ${animate}`}>
            <div >

                <FontAwesomeIcon icon={faExclamationCircle} className="text-2xl" /> 
                <div className="bg-red-600"></div>
                <div className="bg-green-600"></div>
            </div>
            <div className="pl-3 pt-1">
              {message}
            </div>
        </div>
    )
}

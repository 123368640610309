export default () => {
    return (
        <div className="p-5 text-center pt-32 bg-gray-100 min-h-screen">
            <div className="text-6xl mb-3 font-bold">
                404
            </div>
            <div>
             Halaman Tidak Ditemukan        
            </div>
        </div>
    )
}
import { useEffect, Fragment } from 'react'
import { BrowserRouter, Route, Routes, Router  } from "react-router-dom";
import Dashboard from './menu/Dashboard';
import Register from './menu/Register';
import Auth from './api/Auth';
import Login from './menu/Login';
import Absen from './menu/Absen';
import Navbar from './components/Navbar';
import NotFound from './menu/NotFound';
import KembalikanKartu from './menu/KembalikanKartu';
import './App.css'
import { SubDir } from './config/Config';
export default function App() {
  useEffect(() => {
    
    return () => {
      
    };
  }, []);
  return (  
    <BrowserRouter>
    <Auth.AuthProvider>  
     <Auth.AuthConsumer>
    

          {
            ({ state, dispatch, logout }) => {
              
              return(
                    <Fragment>
                    {
                      state.isLogin ?
                      <Navbar dispatch={ dispatch } jabatan={ state.jabatan } logout={ logout } />

                      : 
                      null
                    }

                    <Routes>
     
                      {
                        state.isLogin ?   
                        <Fragment>   
                                <Route path={SubDir + "app/index/dashboard"} element={<Dashboard dispatch={ dispatch } />} />
                                <Route path={SubDir + "app/index/register"} element={<Register dispatch={ dispatch } />} />
                                <Route path={SubDir + "app/index/kembalikanKartu"} element={<KembalikanKartu dispatch={ dispatch } />} />
                                <Route path={SubDir + "app/index/modeAbsen"} element={<Absen dispatch={ dispatch } />} />
                                <Route path={SubDir + "app/index/dashboard"} element={<Dashboard dispatch={ dispatch } />} />
                             
                        </Fragment>
                        :
                        <Route path="*" element={<Login />} />

                      }
                      <Route path="*" element={<NotFound/>}/>
                    

                    </Routes>  
                  </Fragment>
              )
            }
          }
         
        
    
      </Auth.AuthConsumer>
    </Auth.AuthProvider>
    </BrowserRouter>
  )
}
export  const isRequired = (values, entries) => {
    let isValid = true;
    let str_msg = [];

    for(let d of entries){
        if (values[d] === "") {
            isValid  = false;
            str_msg.push(d);
        }
    }
    return {
        status: isValid,
        message: str_msg.join(", ") + " required"
    }
}
export const DMYHIS = (str) => {
    if(!str){
        return ""
    }
    let spl = str.split(" ");
    return spl[0].split("-").reverse().join("-") + " " + spl[1];
}
export const resetValue = (values, domId=[]) => {
    let data = values;

    for(let d in data){
        if(typeof values[d] === "boolean"){
            data[d] = false;
        }else if (typeof values[d] === "number") {
            data[d] = 0
        }else if (typeof values[d] === "string") {
            data[d] = ""
        }else if (typeof values[d] === "object") {
            data[d] = null
        }
    }
    for(let i of domId){
        const dc = document.getElementById(i)
        if(dc){
            dc.value = "";
        }
    }
    return data;
}
export const listBulan  =   ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober","November","Desember"];
export const getLabelsMonth = (tgl_awal, tgl_akhir) => {
  const bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober","November","Desember"];
  const tgl_1 = tgl_awal.split("-");
  const tgl_2 = tgl_akhir.split("-");
  const mulaiBulan = parseInt(tgl_1[1]);
  const selesaiBulan = parseInt(tgl_2[1]);
  const mulaiTahun = parseInt(tgl_1[0]);
  const selesaiTahun = parseInt(tgl_2[0]);
  let finalData = [];
  let b = mulaiBulan;
  for(let i = mulaiTahun; i <= selesaiTahun; i++){
    for(b; b <= 12; b++){
     finalData.push(`${bulan[b-1]} ${i}`);
      if (`${i}-${b}` === `${selesaiTahun}-${selesaiBulan}`) {
          break;
      }
    }
    if (b > 12) {
        b = 1;
    }
  }
  return finalData
}

export const now = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy + "-" + mm + "-" + dd;
}
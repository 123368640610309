import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

export const InputText = ({ Title, Type="text", Name, Placeholder, Status="", TitleClass="", elementClass, InputClass="", other={} }) => {
    const State = (Status == "") ? "" : "border-red-500"
    const [ show, setShow ] = useState(false)
    return (
        <div className={elementClass}>
            <div className="mb-1 text-[14px] ">{ Title }</div>
            <div className="flex">
                
                <input type={(!show) ? Type : "text"} id={Name} name={Name} className={" text-[14px] mb-1 border-2 p-2 pl-5 w-full rounded-md bg-gray-100 " + State} placeholder={Placeholder} { ...other } />
                {
                    (Type == "password") ? 
                        <div className=" -ml-7 mt-2 right-0">
                            <FontAwesomeIcon className="text-gray-500" icon={(!show) ? faEye : faEyeSlash} onClick={() => setShow(!show)} />                
                        </div>
                    :
                    null
                }
            </div>

            <div className="text-red-500 text-xs -mt-1 italic ">{ Status }</div>
        </div>
    )
}


export const TextArea = ({ Title, Type="text", Name, Placeholder, Status="",TitleClass="",elementClass, InputClass="", other={}  }) => {
    const State = (Status == "") ? "" : "border-red-500"
    return (
        <div className={elementClass}>
            <div className="mb-1 text-[14px]">{ Title }</div>
            <textarea type={Type} id={Name} name={Name} className={" text-[14px] border-2 p-2 pl-5 w-full rounded-md bg-gray-100 " + State} placeholder={Placeholder} { ...other } />
            <div className="text-red-500 text-xs -mt-1 italic ">{ Status }</div>
        </div>
    )
}

export const Radio = ({ Title, Name, Id, onChange, checked }) => {
    return (
        <div>
            <div className="form-check">
                <input checked={checked} onChange={onChange} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name={Name} id={Id}/>
                <label className="form-check-label inline-block text-gray-800" htmlFor={Id}>
                    { Title }
                </label>
            </div>
        </div>
        
    )
}
export const Select = ({ TitleClass, InputClass, Title, Name, Placeholder, Status="", Other={},children }) => {
    const State = (Status == "") ? "" : "border-red-500"

    return (
        <div className="mb-2">
            <div className="mb-1 text-[14px]">{ Title }</div>
            <select id={Name} {...Other}  name={Name} className={" text-[14px] border-2 p-2 pl-5 w-full rounded-md bg-gray-100 " + State} placeholder={Placeholder}>
            {children}
            </select>
            <div className="text-red-500 text-xs mt-1 italic ">{ Status }</div>
        </div>
    )
}

export const AutoComplete = ({ classDropdown="", Data ,Title, Type="text", Name, Placeholder, Status="", onSelect }) => {
    const State = (Status == "") ? "" : "border-red-500"
    const [Fokus, setFokus] = useState(false);
    const [cari, setCari]   = useState("");
    const hideAuto = () => {
        setTimeout(() => {
            setFokus(false)
        }, 400);
    }
    const Filter            = Data.filter((x) => { 
        return x.title.toLowerCase().indexOf(cari.toLowerCase()) !== -1
     }).slice(0,5)
    return (
        <div className="mt-2">
            <div className="mb-1">{ Title }</div>
            <input id="ac-form" type={Type} name={Name} onFocus={() => { setFokus(true) }} onBlur={hideAuto} onChange={(e) => { setCari(e.target.value) }} className={"mb-1 relative border-2 border-gray-300 p-2 pl-5 w-full rounded-md bg-gray-200 " + State} placeholder={Placeholder} />
            {
                (Fokus) ?
                <div className={" h-48 absolute bg-white pl-3 pt-2 shadow-lg pt-1 pr-3 pb-2 rounded-b-lg overflow-y-scroll " + classDropdown}>
                        {
                            (Filter.length == 0) ?
                            <div className=" mb-1 p-1 pl-3 pr-3">Tidak ada data</div>
                            :
                            Filter.map((x) => {
                                return <div onClick={() => onSelect(x)} key={x.id} className=" w-full cursor-pointer bg-gray-100 hover:bg-blue-300 mb-1 p-1 pl-3 pr-3 rounded-md transition ease-in duration-100">{x.title}</div>
                            })
                        }
                </div>
                : 
                null
            }
            
            <div className="text-red-500 text-sm -mt-1 italic ">{ Status }</div>
        </div>
    )
}
